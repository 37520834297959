import Axios from 'axios'
import Router from '@/router'
import store from "../store";
import AuthService from "./auth"
import Cookies from "js-cookie";
import Vue from 'vue'


const axios = Axios.create({
  baseURL: process.env.BASE_URL + 'api/',
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  validateStatus: status => (status >= 200 && status < 300),
  headers: {
    'X-REQUESTED-WITH': 'XMLHttpRequest'
  }
})

axios.interceptors.request.use(config => {
  let sid = store.getters.user.sessionId;
  if (!sid) {
    sid = Cookies.get('sessionid')
  }
  let token = Cookies.get('token')
  let ixpToken = Cookies.get('ixpToken')
  if(!token && ixpToken) {
    token = ixpToken
  }
  config.params = {
    sessionid: sid,
    ...config.params,
  }
  config.headers = {
    'Authorization': token,
    'AuthorizationIxp': ixpToken,
    ...config.headers
  }
  return config
}, err => {
  return Promise.resolve(err)
})

// 需要登陆的标志, 为 true 时会拦截接口异常(吞掉不再reject), 并直接跳转登陆页
let loginFlag = false
let lastLoginFlag = loginFlag

axios.interceptors.response.use(response => {
  if(response.data.code === 202 && response.data.url) {
    if ('token' in response.data) {
      Cookies.set('token', response.data.token)
    } else {
      Cookies.remove('token')
    }
    window.location.href = response.data.url
  }
  lastLoginFlag = loginFlag
  loginFlag = false
  if (response.headers['content-type'].includes('openxmlformats')) {
    download(response)
    return { success: true }
  }
  if (response.headers['content-type'].includes('json') && response.config.responseType === 'blob') {
    // 当下载接口报错时候处理，这种情况返回的一定是错误信息，return Promise.reject
    return new Response(response.data).text().then(res => {
      return Promise.reject(JSON.parse(res))
    })
  }
  if(!response.data.success) {
    if(response.data.code === 601) {
      AuthService.callLogout().then(() => {
        store.dispatch("user/logout")
      })
      Router.replace({ name: 'login' })
      return
    }
  }
  return response.data
}, error => {
  lastLoginFlag = loginFlag
  loginFlag = false
  const response = error.response
  errorHandle(response.status)
  return Promise.reject(response.message || '服务器错误')
})

export const errorHandle = function (code) {
  loginFlag = lastLoginFlag
  if (code === 601) {
    if (!lastLoginFlag) {
      loginFlag = true
      Vue.prototype.$message.error('访问超时，请重新登陆！')
      AuthService.callLogout().then(() => {
        store.dispatch("user/logout")
        const cb = window.location.href
        Router.replace({name: 'login', params: {callback: `${encodeURIComponent(cb)}`}})
      })
    }
  } else if (code === 610) {
    Router.replace({ name: 'error-610' })
  }  else if (code === 611) {
    Router.replace({ name: 'error-611' })
  } else if (code === 621) {
    Router.replace({ name: 'error-621' })
  } else if (code === 401) {
    store.dispatch("user/logout")
    const cb = window.location.href
    Router.replace({name: 'login', params: {callback: `${encodeURIComponent(cb)}`}})
  }
}
export const download = function(response) {
  const blob = new Blob([response.data],
    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
  const fileName = response.headers['content-disposition'].split('"')[1]
  const link = document.createElement('a')
  link.download = decodeURI(fileName)
  link.href = window.URL.createObjectURL(blob)
  link.click()
  window.URL.revokeObjectURL(link.href)
}
export default axios
