// import Vue from 'vue'
export default {
    install(Vue){
        Vue.directive('preventReClick',{
            inserted(el,binding){
                el.addEventListener('click',()=>{
                    if(!el.disabled){
                        el.disabled=true
                        setTimeout(()=>{
                            el.disabled =false
                        },binding.value || 3000)
                    }
                })
            }
        })
    }
}