<template>
  <div class="page-wrap scroll">
    <!-- <div class="page-header">
      <div class="page-title">
        <slot name="pageHeaderTitle">{{ title }}</slot>
      </div>
      <yv-divider direction="vertical" v-if="$slots.pageHeaderTab"></yv-divider>
      <div class="page-header__tabs" v-if="$slots.pageHeaderTab">
        <slot name="pageHeaderTab"></slot>
      </div>
      <div class="page-header__tips">
        <slot name="pageHeaderTips"></slot>
      </div>
    </div> -->
    <div class="page-main">
        <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "page",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@a/style/variables.scss";
.page-wrap {
  height: calc(100vh - $header-height);
  .page-header {
    position: relative;
    display: flex;
    align-items: center;
    height: $header-height;
    padding: 0 16px;
    background-color: #fff;
    box-shadow: 0 1px 0 #eeeeee;
    min-height: 50px;
    .page-title {
      font-size: 16px;
      color: $text-primary;
      font-weight: 600;
      margin-right: 16px;
    }
    .page-header__tabs {
      margin-left: 24px;
      ::v-deep .yv-tabs__header {
        height: $header-height !important;
        margin: 0;
        .yv-tabs__nav-wrap {
          .yv-tabs__active-bar {
            height: 3px;
          }
          .yv-tabs__item {
            height: $header-height;
            line-height: $header-height;
          }
          &::after {
            display: none;
          }
        }
      }
    }
    .page-header__tips {
      font-size: 14px;
      position: absolute;
      right: 16px;
    }
  }

  .page-main {
    padding: 16px;

    .white-box {
       //min-height: calc(100vh - #{$header-height * 2} - 32px);
    }

    ::v-deep .yv-scrollbar__wrap {
      box-sizing: border-box;
      padding: 16px;
      /*height: calc(100vh - #{$header-height * 2});*/
      height: calc(100vh - #{$header-height});
      overflow-x: hidden;

    }
    .page-main__toolbar {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $border-color-light;
      padding-bottom: 12px;
      .search-input {
        width: 210px;
      }
      & + .page-main__content {
        margin-top: 12px;
      }
    }
    .page-main__content {

    }
  }
}
</style>
