<template>
  <div class="header-container">
    <div class="header">
      <div class="navbar">
        <div class="product-tabs">
          <div @click="gotoIxpWeb" class="product-tabs-item"><svg-icon iconClass="pi-ixp" size="17" class="nav-icon" style="margin-right:5px"/><span>互联业务</span></div>
          <div class="product-tabs-item active"><svg-icon iconClass="pi-mc" size="17" class="nav-icon" style="margin-right:5px"/><span>上云业务</span></div>
        </div>
      </div>
      <div class="right">
        <!-- <a  class="header-link"> 互联专网 <yv-icon class="ipx ixp-link" size="12" /></a> -->
        <yv-menu mode="horizontal" class="header-menu" >
          <yv-submenu index="5" class="account-title" :popper-append-to-body="false" >
            <template slot="title"><div class="header-user-icon"></div><span>&nbsp;{{name}}&nbsp;</span></template>
            <yv-menu-item>
              <a @click="gotoIxpCenter">
                <svg-icon iconClass="qyzx" size="16" class="nav-icon" style="fill:#fff" />
                企业中心
              </a>
            </yv-menu-item>
            <!--            <yv-menu-item>-->
            <!--              <a @click="handleLogout">-->
            <!--                <svg-icon iconClass="notice" size="16" class="nav-icon" style="fill:#fff" />-->
            <!--                公告栏-->
            <!--              </a>-->
            <!--            </yv-menu-item>-->
          </yv-submenu>
        </yv-menu>
        <div class="logout" @click="handleLogout">
          <img src="../../assets/imgs/logout.png" width="24" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import AuthService from "@/api/auth"
  import Cookies from "js-cookie";
  import axios from 'axios'
  import {IXP_USER_URL, IXP_WEB_URL,IXP_CENTER_URL, IXP_LOGIN_URL} from "@util/constant";
  axios.defaults.withCredentials = true
  function checkCookie() {
    axios.get(IXP_USER_URL).then(response => {
      if(!response.data || !('cookie' in response.data)) {
        Cookies.remove('token')
        window.location = IXP_LOGIN_URL
      }
    }).catch(()=>{
      Cookies.remove('token')
      window.location = IXP_LOGIN_URL
    })
  }
  export default {
    name: "Header",
    data() {
      return {
        name:this.$store.state.user.user.chineseName || '',
        pageTitle: this.$router.currentRoute.meta.title,
        checkCookieTimer: null,
      };
    },
    computed: {
      ...mapGetters(["sidebar"]),
      ...mapGetters(["user"]),
    },
    watch: {
      $route: {
        immediate: true,
        handler: function () {
          this.pageTitle = this.$router.currentRoute.meta.title
        }
      },
      "$store.state.user.user.chineseName":{
        deep:true,
        immediate: true,
        handler:function (n){
          this.name=n
        }
      },
    },
    components: {
    },
    created() {
      this.init()
    },
    methods: {
      fun_checkUser() {
        axios.get(IXP_USER_URL).then(response => {
          if(!response.data || !('cookie' in response.data)) {
            Cookies.remove('token')
            window.location = IXP_LOGIN_URL
          }
        }).catch(()=>{
          Cookies.remove('token')
          window.location = IXP_LOGIN_URL
        })
      },
      init() {
        document.addEventListener('visibilitychange', this.fun_checkUser, true);
        this.checkCookieTimer = setInterval(function (){
          checkCookie()
        }, 60000)
      },
      toggleSideBar() {
        this.$store.dispatch("app/toggleSideBar");
      },
      handleLogout() {
        Cookies.remove('token')
        AuthService.callLogout().then(() => {
        })
        if(this.checkCookieTimer) {
          clearInterval(this.checkCookieTimer)
        }
      },
      gotoIxpWeb() {
        window.open(IXP_WEB_URL, 'shixp')
      },
      gotoIxpCenter() {
        window.open(IXP_CENTER_URL, 'shixp')
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@a/style/variables.scss";
  .right{
    display: flex;
    align-items: center;
  }
  .header-link{
    background-image: linear-gradient(270deg, #018DEA 0%, #00BCF9 100%);
    border-radius: 15px;
    color: #fff;
    display: block;
    line-height: 30px;
    padding: 0 16px;
    font-size: 13px;
    font-weight: bold;
    white-space: nowrap;
    letter-spacing:1px;
  }
  .header {
    height: $header-height;
    background-image:url('~@a/imgs/header-bg.jpg') ;
    background-size: cover;
    color: white;
    line-height: $header-height;
    padding: 0;
    display: flex;
    position: relative;
    z-index: 50;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  .navbar {
    display: flex;
    align-items: center;
  }
  .navbar-brand {
    height: $header-height;
    display: inline-block;
    min-width: 180px;
    padding: 0 20px;
    font-size: 15px;
    position: relative;
  .logo-figure {
    display: flex;
    align-items: center;
    height: 50px;
  img {
    height: 32px;
  }
  }
  .logo-text {
    display: inline-block;
    font-size: $font-lg;
    color: white;
  &:before {
     content: "▪";
     display: inline-block;
     padding: 0 8px;
     color: rgba(255, 255, 255, 0.6);
   }
  }
  }
  &::v-deep .yv-menu--horizontal.yv-menu {
  .account-title {
    margin-right: 10px;
    margin-left: 12px;
  }
  .yv-menu-item a {
    display: flex;
    align-items: center;
    width: 100%;
  .nav-icon{margin-right: 10px;}
  }
  .yv-menu-item,
  .yv-submenu__title {
    height: 50px;
    line-height: 50px;
    border-bottom: none;
    padding: 0 12px;
    color: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
  a:hover {
    text-decoration: none;
  }
  &:hover,
  &:hover i {
     background: none;
     color: white;
   }
  i {
    color: rgba(255, 255, 255, 1);
    vertical-align: baseline;
  // margin-right: 2px;
    transform:scale(0.9);
  }
  .is-dot {
    right: 8px;
    bottom: 15px;
    top: auto;
    border: none;
    background-color: #fc4700;
    width: 6px;
    height: 6px;
  }
  }
  .yv-submenu {
  .yv-submenu__icon-arrow {
    font-size: $font-sm;
    vertical-align: middle;
  }
  }
  .yv-submenu:focus .yv-submenu__title,
  .yv-submenu.is-active .yv-submenu__title {
    color: rgba(255, 255, 255, 0.8);
    border-bottom: none;
  }
  }
  .yv-menu {
    background: none;
    border-bottom: none;
  }
  &::v-deep .yv-menu--horizontal:not(.yv-menu),
  &::v-deep .yv-menu--vertical:not(.yv-menu) {
  .yv-menu--popup {
  // border: $border-base;
    min-width: 180px;
    box-shadow:none;
    background-color: #015eea;
  .yv-menu-item {
    font-size: $font-base;
    height: 32px;
    line-height: 32px;
    width: 100%;
    background: transparent;
  &.business-menu-item {
  &:first-child {
     border-bottom: 1px solid #e5e5e5;
     padding-bottom: 6px;
     margin-bottom: 6px;
   }
  }

  &:hover {
     background: #00c0fa;
   }
  a {
    color: #fff;
  // display: block;
  // flex: 1;
  // width: 100%;
  }
  i {
    color: #fff;
    margin-right: 5px;
    font-size: 14px;
    vertical-align: baseline;
  }
  &.active {
     background-color: #e7f6f2;
  a, i {
    color: $primary;
  }
  }
  }
  }
  }
  &::v-deep .account-title .yv-icon-arrow-down{
     font-size: 12px;
     margin-left: 10px;
  &::before{
     content:'\e70f'
   }
  }
  &::v-deep .yv-menu-item.is-active,
  &::v-deep .yv-menu-item:hover,
  &::v-deep .yv-submenu__title:hover {
     color: $primary;
  .figure {
  &:before {
     color: $primary;
   }
  i {
    color: $white;
  }
  }
  }
  }
  .header-user-icon{
    width: 30px;
    height: 30px;
    background-image: url('~@a/imgs/header-user-icon.png');
    background-size: cover;
    margin-right: 5px;
    margin-left: 20px;
  }
  .logout{
    margin-right: 20px;
    margin-left: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .product-tabs{
    display: flex;
    align-items: center;

  &-item {
     padding: 0 32px;
     font-size: 16px;
     font-weight: bold;
     background-color: rgba(15,47,101,.41);
   // background-image: linear-gradient(0deg, rgba(15,47,101,.41) 0%, rgba(15,47,101,.41) 98%);
     color: rgba(255, 255, 255, 0.8);
     transition: all .3s;
     display: flex;
     align-items: center;
  .nav-icon{
    color: rgba(255, 255, 255, 0.8);
  }
  &:hover{
     cursor: pointer;
     background-image: linear-gradient(0deg, #0162ea8f 0%, rgba(0,188,249,0) 100%);
   }
  &.active {
     background-color: transparent;
     color: rgba(255, 255, 255, 1);
     background-image: linear-gradient(0deg, #0161EA 0%, rgba(0,188,249,0.00) 85%);
     position: relative;
     overflow: hidden;
  .nav-icon{
    color: rgba(255, 255, 255, 1);
  }
  &::after {
     content: '';
     position: absolute;
     width: 12px;
     height: 12px;
     background-color: #fff;
     left: 50%;
     margin-left: -6px;
     bottom: -8px;
     transform: rotate(45deg);
   }
  }
  }
  }
</style>

