export default [
    {
        path: '/connection',
        name: 'connection',
        component: () => import("@/views/connection"),
        meta: {title: '上云业务', activeMenu: 'connection'}
    },
    {
        path: '/connection/create',
        name: 'connectionCreate',
        component: () => import('@/views/connection/create'),
        meta: { title: '上云业务-创建', activeMenu: 'connection',isCollapse: true }
    },
    {
        path: '/connection/detail',
        name: 'connectionDetail',
        component: () => import('@/views/connection/detail'),
        meta: { title: '上云业务-详情', activeMenu: 'connection' }
    },
    {
        path: '/connection/renew',
        name: 'connectionRenew',
        component: () => import('@/views/connection/renew'),
        meta: { title: '续费上云业务', activeMenu: 'connection' }
    },
]