<template>
  <div class="page-wrap">
    <div class="page-header">
      <div class="page-title">
        {{ title }}
      </div>
    </div>
    <div class="page-main">
      <WhiteBox>
        <div class="permission-header">
          <img :src="PermissionPng" />
          <p class="header-title">没有访问权限</p>
        </div>
        <div class="permission-content">
          <yv-descriptions
            :column="1"
            :labelStyle="{width: '100px'}"
            :contentStyle="{flex: '1'}"
            :colon="false"
          >
            <yv-descriptions-item label="错误描述">没有权限访问服务，请联系管理员</yv-descriptions-item>
          </yv-descriptions>
        </div>
      </WhiteBox>
    </div>
  </div>
</template>

<script>
import PermissionPng from "@a/imgs/no-permission.png";
import {mapGetters} from "vuex";
export default {
  name: "PermissionPage",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["user"])
  },
  data () {
    return {
      PermissionPng: PermissionPng
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@a/style/variables.scss";
.page-wrap {
  .page-header {
    position: relative;
    display: flex;
    align-items: center;
    height: $header-height;
    padding: 0 16px;
    background-color: #ffffff;
    // box-shadow: $white-box-shadow;
    border-bottom:$border-base;
    min-height: 51px;
    .page-title {
      font-size: 16px;
      color: $text-primary;
      font-weight: 600;
      margin-right: 16px;
    }
  }

  .page-main {
    margin: 16px;
    .permission-header {
      width: 300px;
      margin: 0 auto;
      font-size: 14px;
      text-align: center;
      color: $text-secondary;
      .header-title {
        font-size: 26px;
        color: $text-primary;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
    .permission-content {
      border: 1px solid $bg-light;
      background-color: $bg-base;
      padding: 24px;
      width: 600px;
      margin: 45px auto;
      ::v-deep .yv-descriptions__body {
        background-color: $bg-base;
      }
    }
  }
}
</style>
