import Cookies from "js-cookie";
const state = {
  user: {}
};

const mutations = {
  setUser (state, user) {
    state.user = user
  },
  clearUser (state) {
    state.user = {}
  }
};

const actions = {
  setUser ({ commit }, user) {
    Cookies.set('sessionid', user.sessionId)
    commit('setUser', user)
  },
  logout ({ commit }) {
    Cookies.remove('sessionid')
    commit('clearUser')
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
