export default [
    {
        path: '/workbench',
        name: 'workbench',
        component: () => import("@/views/workbench"),
        meta: {title: '工作台', activeMenu: 'workbench'}
    },
    {
        path: '/certification',
        name: 'certification',
        component: () => import("@/views/workbench/certification"),
        meta: {title: '工作台', activeMenu: 'workbench'}
    },
    {
        path: '/editCert',
        name: 'editCert',
        component: () => import("@/views/workbench/edit-cert"),
        meta: {title: '工作台', activeMenu: 'workbench'}
    },
]