import axios from './index'

function getCurrentUserInfo (ticket, path) {
  if (ticket) {
    return axios.post('getUserInfo?ticket=' + ticket + '&path=' + path, ).then(data => data)
  } else {
    return axios.post('getUserInfo?path=' + path).then(data => data)
  }
}

function getCurrentUserInfoByIxp (ixpToken, path) {
  return axios.post('getUserInfo?ixpToken=' + ixpToken + '&path=' + path, ).then(data => data)
}

function callLogin(username, password,type) {
  return axios.post('login/', {
    'loginname': username,
    'password': password,
    'type':type
  }).then(data => data)
}

function callLogout () {
  return axios.get('logout/').then(data => data)
}

export default {
  getCurrentUserInfo,
  callLogin,
  callLogout,
  getCurrentUserInfoByIxp,
}
