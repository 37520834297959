<template>
  <div class="page-detail-wrap" :class="{'has-footer-bar':hasFooterBar}">
    <div class="page-detail-header">
      <router-link v-if="!onlyBack" :to="backPath" class="page-detail__back-btn">
        <yv-icon class="yv-icon-arrow-left"></yv-icon>
      </router-link>
      <div v-if="onlyBack" class="page-detail__back-btn" @click="backRouter">
        <yv-icon class="yv-icon-arrow-left"></yv-icon>
      </div>
      <yv-divider direction="vertical"></yv-divider>
      <div class="page-detail-title">
        {{ Pagetitle }}
        <slot name="Pagetitle"></slot>
      </div>
      <div class="page-detail-tips" v-if="$slots.pageDetailTips">
        <slot name="pageDetailTips"></slot>
      </div>
    </div>
    <div class="page-detail-main">
      <div class="shadow"></div>
      <div v-if="$slots.pageDetailMsg" class="page-detail-msg">
        <slot name="pageDetailMsg"></slot>
      </div>
      <div class="p16"><slot></slot></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageDetail",
  props: {
    backPath: {
      type: [Object, String],
      default: "/",
    },
    Pagetitle: {
      type: String,
      default: "",
    },
    hasFooterBar: {
      type: Boolean,
      default: false
    },
    onlyBack: Boolean,
  },
  methods: {
    backRouter() {
      this.$router.back(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@a/style/variables.scss";
.page-detail-wrap {
  display: flex;
  flex-direction: column;
  height: calc(100% - $header-height);
  .shadow{
    position:relative;
    z-index: 5;
  }
  .shadow::before{
    content: '';
    box-shadow: 0 0 10px 1px rgba(0,0,0,30%);
    position: fixed;
    width: 100%;
  }
  .shadow::after{
    content: '';
    width: 100%;
    height: 20px;
    background: linear-gradient(to bottom, #fff 50% , transparent);
    position: absolute;
  }
  .page-detail-header {
    position: relative;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 16px;
    background-color: #fff;
    // box-shadow: $white-box-shadow;
    border-bottom: $border-base-light;
    z-index: 10;
    .page-detail__back-btn {
      cursor: pointer;
      color: $text-primary;
      vertical-align: middle;
      padding: 4px 8px;
      border-radius: $border-radius-base;
      transition: all 0.2s;
      border: 1px solid transparent;
      overflow: hidden;
      font-weight: bold;
      &:hover {
        text-decoration: none;
        background-color: $primary-color-1;
        color: $primary;
      }
      &:active {
        background-color: mix($primary, $primary-color-1, 5%);
      }
    }
    .yv-divider {
      background: $border-color-base;
    }
    .page-detail-title {
      display: flex;
      align-items: center;
      margin-left: 12px;
      font-weight: 700;
      color: $text-primary;
      font-size: 14px;
      ::v-deep .yv-breadcrumb {
        display: flex;
        align-items: center;
        .yv-breadcrumb__item {
          display: flex;
          align-items: center;
        }
        .yv-breadcrumb__inner {
          display: flex;
          align-items: center;
          font-weight: bold;
        }
      }
    }

    .page-detail-tips {
      font-size: 14px;
      position: absolute;
      right: 16px;
    }
  }
  .page-detail-header ~ .page-detail-tabs {
    margin-top: -1px;
    position: relative;
    border-top: 1px solid $bg-base;
  }
  .page-detail-main {
    flex: 1;
    overflow-y: auto;
    // padding: 16px;
  }
  .page-detail-msg{
      position:sticky;
      top: 0;
      z-index: 10;
    }
  .page-detail-main__scrollbar {
    overflow: hidden;
    ::v-deep {
      .yv-scrollbar__wrap {
        box-sizing: border-box;
        padding: 16px;
        height: calc(100vh - #{$header-height});
        overflow-x: hidden;
      }
    }
  }
}
</style>
