import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../views/layout.vue";
// import RouterDemo from './router-demo'
import RouterWorkbench from './router-workbench'
import RouterOrder from "@/router/router-order";
import RouterBill from "@/router/router-bill";
import RouterConnection from "@/router/router-connection";
import RouterUser from "@/router/router-user";
import store from "../store";
import AuthService from "../api/auth"
import Utils from '@/util/utils'
import Cookies from "js-cookie";
import axios from "../api";
import {IXP_USER_URL, IXP_LOGIN_URL} from "@util/constant";

Vue.use(VueRouter);
const originalPush2 = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location){
  return originalPush2.call(this,location).catch(err => err)
}
const routes = [
  {
    path: "*",
    name: "Layout",
    component: Layout,
    redirect:'/workbench',
    children: [
      {
        path: '/permission-error',
        name: 'permission-error',
        component: () => import("@/views/error/permission-error"),
        meta: {title: '没有权限', activeMenu: 'permission-error'}
      },
      ...RouterWorkbench,
      ...RouterBill,
      ...RouterOrder,
      ...RouterConnection,
      ...RouterUser
    ]
  },
  // {
  //   path: "/demo",
  //   name: "Demo",
  //   redirect: '/demo',
  //   component: () => import("@/demo/layout"),
  //   children: [
  //       ...RouterDemo,
  //   ],
  // },
  {
    path: '*',
    name:'default',
    redirect: '/'
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function getAndSetUser(next) {
  if (store.getters.user&&store.getters.user.username) {
    next()
  } else {
    const ticket = Utils.GetQueryString('ticket')
    const paramPath = Utils.GetQueryString('path')
    const path = paramPath ? paramPath : window.location.pathname
    const token = Cookies.get('token')
    if(token || ticket) {
      AuthService.getCurrentUserInfo(ticket, path).then(user => {
        if (user.success) {
          store.dispatch('user/setUser', user.data)
          Cookies.set('token', user.data.token)
          next()
        }
      })
    } else{
      axios.defaults.withCredentials = true
      axios.get(IXP_USER_URL).then(response => {
        if(response && response.cookie){
          AuthService.getCurrentUserInfoByIxp(response.cookie, path).then(user => {
            if (user.success) {
              store.dispatch('user/setUser', user.data)
              Cookies.set('token', user.data.token)
              next()
            }
          })
        } else {
          window.location = IXP_LOGIN_URL + (path === '/' ? '' : '%3Fpath%3D' + path)
        }
      }).catch(()=>{
        AuthService.getCurrentUserInfo(ticket, path).then(user => {
          if (user.success) {
            store.dispatch('user/setUser', user.data)
            Cookies.set('token', user.data.token)
            next()
          }
        })
      })
    }
  }
}

router.beforeEach((to, from, next) => {
  if (to.name.startsWith('error-') || to.name.startsWith('demo') || to.name === 'login' || to.name === 'permission-error' || to.name === 'regist') {
    next()
  } else {
    getAndSetUser(next)
    // next() // 开启demo
  }
})
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router;
