export default {
  getBase64(file) {
    function compress(img, type, maxHeight, flag) {
      let canvas = document.createElement('canvas');
      let ctx2 = canvas.getContext('2d');
      let ratio = img.width / img.height;
      let width = img.width, height = img.height;
      // 根据flag判断是否压缩图片
      if (flag) {
        // 压缩后的图片展示在输入框
        height  = maxHeight;
        width = maxHeight * ratio; // 维持图片宽高比
      }
      canvas.width = width;
      canvas.height = height;
      ctx2.fillStyle = '#fff';
      ctx2.fillRect(0, 0, canvas.width, canvas.height);
      ctx2.drawImage(img, 0, 0, width, height);
      let base64Data = canvas.toDataURL(type, 0.75);
      if (type === 'image/gif') {
        let regx = new RegExp("/(?<=data:image).*?(?=;base64)/"); // 正则表示时在用于replace时，根据浏览器的不同，有的需要为字符串
        base64Data = base64Data.replace(regx, '/gif');
      }
      canvas = null;
      ctx2 = null;
      return base64Data;
    }
    return new Promise(function (resolve, reject) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        const result = reader.result
        let img = new Image()
        img.onload = function () {
          let compressedDataUrl = compress(img, file.type, 90, true);
          let url = compress(img, file.type, 90, false);
          img = null;
          resolve({
            data: file,
            compressedDataUrl,
            url,
            type: 'image'
          })
        }
        img.src = result
      },
          reader.onerror = function (error) {
            reject(error)
          }
    })
  },

  dateFormatter: function (time, format = '{y}-{m}-{d} {h}:{i}:{s}') {
    let date
    if (time instanceof Date) {
      date = time
    } else if (typeof time === 'number') {
      const timestamp = Number((time.toString() + '000').substr(0, 13))
      date = new Date(timestamp)
    } else if (typeof time === 'string') {
      date = new Date(time)
    } else {
      return null
    }

    if (date.toString() === 'Invalid Date') return null
    const formatObj = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds()
    }
    return format.replace(/{([ymdhis])+}/g, (result, key) => {
      let value = formatObj[key]
      if (result.length > 0 && value < 10) {
        value = '0' + value
      }
      return value || 0
    })
  },

  formatNumber(number) {
    const num = parseFloat(number).toString()
    let s = ''
    let p = ''
    if(num.indexOf('.') > 0) {
      s = num.substr(num.indexOf('.')+1);
      p = num.substr(0, num.indexOf('.'));
    } else {
      p = num
    }
    const len = p.length
    if (len <= 3) return p + (s.length > 0 ? '.'+s : '')
    const remainder = len % 3
    let prefix = remainder > 0 ? p.slice(0, remainder) + ',' + p.slice(remainder, len).match(/\d{3}/g).join(',')
      : p.slice(remainder, len).match(/\d{3}/g).join(',')
    return prefix + (s.length > 0 ? '.' + s : '')
  },

  fixNumberToString(number, len) {
    let str = String(number);
    let l = str.length;
    if(l < len) {
      let r = '' + str;
      while(r.length < len) {
        r = '0'+r
      }
      return r;
    } else {
      return str;
    }
  },

  GetQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
    var context = "";
    if (r != null)
      context = decodeURIComponent(r[2]);
    reg = null;
    r = null;
    return context == null || context == "" || context == "undefined" ? "" : context;
  },

  GetMonth(date1, date2) {
    const y1 = Number(date1.substr(0,4))
    const y2 = Number(date2.substr(0,4))
    const m1 = Number(date1.substr(5,2))
    const m2 = Number(date2.substr(5,2))
    return (y2-y1) * 12 + m2 - m1
  }
}
