<template>
  <div :class="classObj" class="app-wrapper">
    <div class="app-container">
      <Sidebar></Sidebar>
      <div class="main-container">
        <Header></Header>
        <transition name="slide-right">
          <router-view></router-view>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
  import Sidebar from "../components/layout/Sidebar";
  import Header from "../components/layout/Header";
  import {mapState} from "vuex";

  export default {
    name: "Layout",
    components: {Sidebar, Header},
    computed: {
      ...mapState({
        sidebar: (state) => state.app.sidebar,
      }),
      classObj() {
        return {
          hideSidebar: !this.sidebar.opened,
          openSidebar: this.sidebar.opened,
          withoutAnimation: this.sidebar.withoutAnimation,
        };
      },
    },
  };
</script>
<style lang="scss" scoped>
@import "~@a/style/variables.scss";
/*
    动画样式
    will-change: transform    优化渲染速度
*/

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  transition: opacity 0.2s;
}
.slide-right-enter {
  opacity: 0;
}
.slide-right-leave-active {
  opacity: 0;
}
.slide-left-enter {
  opacity: 0;
}
.slide-left-leave-active {
  opacity: 0;
}
.app-wrapper {
  display: flex;
  flex: 1;
  flex-basis: auto;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  /* .app-container {
    display: flex;
    flex-basis: auto;
    flex-direction: row;
  }*/
  .app-container {
    flex: 1;
    display: flex;
    height: calc(100% - 50px);
  }
  .main-container {
    transition: margin-left 0.28s;
    flex: 1;
    /*margin-left: $sidebar-width;
    height: calc(100vh - #{$header-height});*/
    position: relative;
    // height: 100vh;
    overflow: hidden;
  }
}
</style>
