<template>
  <section class="siderbar" :class="{'collapse': isCollapse}">
    <div class="logo">
      <!-- <span class="logo-figure"></span> -->
      <img v-if="!isCollapse" src="../../assets/imgs/logo-white.png" width="110" alt="">
      <img v-else src="../../assets/imgs/logo-white-only.png" width="32" alt="">

    </div>
    <ul>
      <li :class="{active: activeMenu==item.path}" v-for="item in menus" :key="item.index" @click="selectMenu(item.path)">
        <router-link class="siderbar-item" :to="item.path">
          <svg-icon :iconClass="item.icon" size="14" class="nav-icon" />
          <span>{{ item.title }}</span>
        </router-link>
      </li>
    </ul>
    <div class="toggle-icon" @click="toggleCollapse" v-if="!isCollapse"><yv-icon name="yv-icon-d-arrow-left"/></div>
    <div class="toggle-icon" @click="toggleCollapse" v-else><yv-icon name="yv-icon-d-arrow-right"/></div>
  </section>
</template>

<script>
  // import Router from '@/router';
  import {mapGetters} from "vuex";
  export default {
    data() {
      return {
        menus:[
          {
            index:'1',
            title:'工作台',
            icon:'home2',
            path:'/workbench',
            show:true
          },
          {
            index:'2',
            title:'上云业务',
            icon:'contact2',
            path:'/connection',
            show:true
          },
          {
            index:'3',
            title:'订单管理',
            icon:'order2',
            path:'/order',
            show:true
          },
          {
            index:'4',
            title:'账单管理',
            icon:'bill2',
            path:'/bill',
            show:true
          },
          {
            index:'5',
            title:'云账户',
            icon:'account2',
            path:'/user',
            show:true
          },
        ],
        activeMenu: '',
        isCollapse: false,
      }
    },
    computed:{
      ...mapGetters(["sidebar"])
    },
    watch: {
      $route: {
        immediate: true,
        handler: function () {
          this.active = this.$route.meta.activeMenu;
          this.activeMenu = this.active ?'/' + this.active : this.$route.path;
          this.isCollapse = this.$route.meta.isCollapse;
          this.$store.dispatch("app/setMenus",this.menus)
        }
      }
    },
    mounted() {
    },
    methods: {
      toggleCollapse() {
        this.isCollapse = !this.isCollapse;
      },
      selectMenu (indexPath) {
        this.activeMenu = indexPath
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "~@a/style/variables.scss";
  .logo{
  // background-color: red;
    height: $header-height;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    width: 155px;
    display: flex;
    align-items: center;
    height: 63px;
    padding-left: 10px;
    justify-content: center;

  .logo-figure{
    width: 110px;
    height: 40px;
    background-image:url('~@a/imgs/logo-white.png') ;
    display: block;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  }
  .siderbar {
    background-color: #fafbfc;
    background-image:url('~@a/imgs/left-bg.jpg') ;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: $sidebar-width;
    box-shadow: 0 0 1px rgb(9 30 66 / 12%), 0 4px 8px rgb(9 30 66 / 12%);
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 20;
    transition: width 0.2s;
    padding-top: 4px;
  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
  }
  ul {
    flex: 1;
    margin-left: 10px;
    background-color: rgba(15,47,101,.41);
    border-radius: 12px 12px 0 0;
    padding-top: 20px;
  }
  &.collapse {
     width: $sidebar-min-width;
  .logo{
    width: 62px;
  }
  .siderbar-item {
  span {
    opacity: 0;
    display: none;
  }
  }
  .nav-icon{
    margin: 0 auto;
  }
  .logo-figure{
    width: 32px;
    height: 32px;
    background-image:url('~@a/imgs/logo-white-only.png') ;
  }
  }
  }
  .toggle-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    position: absolute;
    bottom: 10px;
    left: 18px;
    color: rgba(255,255,255,0.7);
  // align-self: flex-end;
    cursor: pointer;
  &:hover {
     color: rgba(255,255,255,1);
     background-color: rgba(0,0,0,0.18);
   }
  }
  .nav-icon {
    margin-right: 10px;
    fill:currentColor;
    color: #fff;
    vertical-align: middle;
  }
  ul li {
    transition: 0.2s;
    border-radius: 20px;
    margin: 0 6px 16px 6px;
  .siderbar-item {
    overflow: hidden;
    color: #fff;
    display: block;
  // align-items: center;
    vertical-align: middle;
    line-height: 34px;
    height: 34px;
    cursor: pointer;
    padding: 0 15px;
  }
  &:hover {
     background-color: #005bbc;
  .siderbar-item {

  }
  }
  &.active {
  .siderbar-item {
    color: #fff;
  }
  background-image: linear-gradient(270deg, #0161EA 0%, #00BCF9 100%);

  span {
  }
  }
  }
</style>
