export default [
    {
        path: '/order',
        name: 'order',
        component: () => import("@/views/order"),
        meta: {title: '订单管理', activeMenu: 'order'}
    },
    {
        path: '/orderDetail',
        name: 'orderDetail',
        component: () => import("@/views/order/order-detail"),
        meta: {title: '账单详细', activeMenu: 'order'},
    }
]