export default [
    {
        path: '/bill',
        name: 'bill',
        component: () => import("@/views/bill"),
        meta: {title: '账单管理', activeMenu: 'bill'},
    },
    {
        path: '/billDetail',
        name: 'billDetail',
        component: () => import("@/views/bill/bill-detail"),
        meta: {title: '账单详细', activeMenu: 'bill'},
    }
]