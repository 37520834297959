class Enum {
  add (key, value) {
    this[key] = value
    return this
  }
}

export default {
  Enum,
}

export const BILL_STATUS = new Enum()
  .add('DIRTY',{code:'DIRTY',label:'未结清',color:'text-danger'})
  .add('CLEAN',{code:'CLEAN',label:'已结清',color:'text-success'})

//订单状态: 生效中:ACTIVE, 处理中: PROCESSING, 作废: DISABLED, 已结束: FINISHED, 未生效: NEW
//3, "已作废", 4, "合约签署", 5, "合同审核", 6, "合同审核不通过",7, "技术方案确认", 8, "处理中", 10, "已验收"
export const ORDER_STATUS = new Enum()
  .add('0',{label:'处理中',type:'warning',code:'0',value:'0',text:'处理中'})
  .add('3',{label:'作废',type:'default',code:'3',value:'3',text:'作废'})
  .add('4',{label:'处理中',type:'warning',code:'4',value:'4',text:'处理中'})
  .add('5',{label:'处理中',type:'warning',code:'5',value:'5',text:'处理中'})
  .add('6',{label:'失效',type:'danger',code:'6',value:'6',text:'失效'})
  .add('7',{label:'处理中',type:'warning',code:'7',value:'7',text:'处理中'})
  .add('8',{label:'处理中',type:'warning',code:'8',value:'8',text:'处理中'})
  .add('10',{label:'有效',type:'success',code:'10',value:'10',text:'有效'})
  .add('11',{label:'有效',type:'success',code:'11',value:'11',text:'有效'})

//订单类型: 新购:NEW, 续费:RECHARGE, 配置变更: CONFIG_CHANGE
export const ORDER_TYPE = new Enum()
    .add('NEW',{label:'新购'})
    .add('RECHARGE',{label:'续费'})
    .add('CONFIG_CHANGE',{label:'配置变更'})
    .add('REVOKE',{label:'用户撤销'})

//云商类型 有孚云: YOVOLE, 阿里云: ALI, 腾讯云: TENCENT, 华为云: HUAWEI
export const CLOUD_TYPE = new Enum()
    .add('YOVOLE',{label:'有孚云',code:'YOVOLE'})
    // .add('ALI',{label:'阿里云',code:'ALI'})
    // .add('TENCENT',{label:'腾讯云',code:'TENCENT'})
    // .add('HUAWEI',{label:'华为云',code:'HUAWEI'})

//状态: NEW,OPENING,ACTIVE,ERROR,DISCONNECTED
export const CONNECTION_STATUS = new Enum()
    .add('NEW',{label:'创建中',code:'NEW',type:'warning'})
    .add('OPENING',{label:'变更中',code:'OPENING',type:'info'})
    .add('ACTIVE',{label:'生效中',code:'ACTIVE',type:'success'})
    .add('DISCONNECTED',{label:'已失效',code:'DISCONNECTED',type:'default'})
    .add('ERROR',{label:'已失效',code:'ERROR',type:'default'})
    .add('CANCEL',{label:'已失效',code:'CANCEL',type:'default'})
    .add('REVOKING',{label:'撤消中',code:'REVOKING',type:'info'})

//SCALE_NODE/SCALE_NODE_LINE/SCALE_BAND_WIDTH/RENEW
export const RENEW = new Enum()
    .add('SCALE_NODE',{code:'SCALE_NODE',name:'节点变更'})
    .add('SCALE_NODE_LINE',{code:'SCALE_NODE_LINE',name:'链路变更'})
    .add('SCALE_BAND_WIDTH',{code:'SCALE_BAND_WIDTH',name:'带宽变更'})
    .add('RENEW',{code:'RENEW',name:'续期'})

//订购时长单位: 天:D, 周:W, 月:M, 年:Y
export const TIME_UNIT = new Enum()
    .add('D',{code:'D',name:'天'})
    .add('W',{code:'W',name:'周'})
    .add('M',{code:'M',name:'个月'})
    .add('Y',{code:'Y',name:'年'})

//订单产品动作 new=新申请，scaleNode=节点扩缩容，scaleNodeLine=通道扩缩，scaleBandwidth=带宽扩缩，renew=续订，userRevoke=用户撤销
export const ORDER_ACTION = new Enum()
    .add('new',{code:'new',name:'新申请'})
    .add('scaleNode',{code:'scaleNode',name:'节点扩缩容'})
    .add('scaleNodeLine',{code:'scaleNodeLine',name:'通道扩缩'})
    .add('scaleBandwidth',{code:'scaleBandwidth',name:'带宽扩缩'})
    .add('renew',{code:'renew',name:'续订'})
    .add('userRevoke',{code:'userRevoke',name:'用户撤销'})

// const IXP_URL = 'https://portal47.shixp.cn'
const IXP_URL = 'https://account.shixp.cn'
export const IXP_WEB_URL = IXP_URL + '/ixptenant/homeManage/home'
export const IXP_CENTER_URL = IXP_URL + '/ixptenant/Enterprise/center'
export const IXP_CENTER_ACTIVE_URL = IXP_URL + '/ixptenant/Enterprise/center?active=2'
export const IXP_USER_URL = IXP_URL + '/cas/userinfo'
export const IXP_LOGIN_URL = IXP_URL + '/ixp/#/login?service=https%3A%2F%2Fcloud.shixp.cn'
// export const IXP_LOGIN_URL = IXP_URL + '/ixp/#/login?service=http%3A%2F%2Fcloud.shixp.cn'
