import Cookies from "js-cookie";

const state = {
  sidebar: {
    opened: Cookies.get("sidebarStatus") ? !!+Cookies.get("sidebarStatus") : false,
    withoutAnimation: false,
  },
  menus: []
};

const mutations = {
  toggleSideBar: (state) => {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      Cookies.set("sidebarStatus", 1);
    } else {
      Cookies.set("sidebarStatus", 0);
    }
  },
  closeSideBar: (state, withoutAnimation) => {
    Cookies.set("sidebarStatus", 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  setMenus: (state, menus) => {
    state.menus = menus
  }
};

const actions = {
  toggleSideBar({commit}) {
    commit("toggleSideBar");
  },
  closeSideBar({commit}, {withoutAnimation}) {
    commit("closeSideBar", withoutAnimation);
  },
  setMenus({ commit }, menus) {
    commit('setMenus', menus);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
